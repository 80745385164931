const video = document.querySelector('video');

const vwrap = document.querySelector('.js-vwrap');
const cake = document.querySelector('.js-cake');
let cpath = 42;
let buttonEl = document.createElement('button');
buttonEl.classList.add('numbers', 'js-numbers');
buttonEl.innerHTML = '10';

video.addEventListener('click', event => {
    if (window.umami) {
        umami('Video Clicked');
    }
    const videoEl = event.currentTarget;
    cpath -= 10;
    videoEl.style.clipPath = `circle(${cpath}% at 50% 50%)`;

    if (cpath <= 0) {
        videoEl.parentElement.removeChild(videoEl);
        vwrap.appendChild(buttonEl);
    }

    setTimeout(() => {
        buttonEl.classList.add('active');
    }, 100);
});

buttonEl.addEventListener('click', event => {
    if (window.umami) {
        umami('Number Clicked');
    }

    if (event.button === 2) {
        return false;
    }

    buttonEl.innerHTML = buttonEl.innerHTML - 1;
    if (buttonEl.innerHTML == 4) {
        buttonEl.innerHTML = 10;
    }

    if (buttonEl.innerHTML == 0) {
        vwrap.removeChild(buttonEl);
        cake.classList.add('displayed');

        setTimeout(() => {
            cake.classList.add('active');
        }, 100);
    }
});

window.addEventListener('contextmenu', function (e) {
    if (buttonEl.innerHTML == 5) {
        buttonEl.innerHTML = buttonEl.innerHTML - 1;
    }
  e.preventDefault();
}, false);